import * as React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { PRIMARY_COLOR } from "../../theme";

const Logo = () => {
  return (
    <StyledLink to="/">
      <strong>スパコムオンラインサロン</strong>
    </StyledLink>
  );
};

const StyledLink = styled(Link)`
  color: ${PRIMARY_COLOR};
  font-size: 1.25rem;
  display: inline-block;
  padding-top: 0.68rem;
  padding-bottom: 0.68rem;
  margin-right: 1rem;
  line-height: inherit;
  white-space: nowrap;
  text-decoration: none;
  background-color: transparent;
`;
export default Logo;
