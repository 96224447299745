import * as React from "react";
import feature1 from "../../../assets/img/feature1.png";
import styled from "styled-components";
import Container from "../../atoms/Container";
import Section from "./Section";
import { BACKGROUND_COLOR } from "../../../theme";
import Row from "../../atoms/Row";
import Col from "../../atoms/Col";
import Feature from "../../molucules/Feature";
const Features: React.FC = () => {
  return (
    <Section backgroundColor={BACKGROUND_COLOR}>
      <ProblemWrapper>
        <StyledRow>
          <StyledCol>
            <h2>何を解決するかをここに書く</h2>
            <p>問題を定義してここに書く感情的に訴えるよ</p>
          </StyledCol>
        </StyledRow>
        <Feature
          title="なぜこの問題が起こるのかを書く"
          description="その詳細を書く"
          imageUri={feature1}
        />
        <Feature
          title="なぜ解決できないかを書く"
          description="その詳細を書く"
          imageUri={feature1}
          imagePosition="right"
        />
        <Feature
          title="なぜこの問題が起こるのかを書く"
          description="その詳細を書く"
          imageUri={feature1}
        />
      </ProblemWrapper>
    </Section>
  );
};

export default Features;

const ProblemWrapper = styled(Container)`
  padding-right: 1.5rem;
  padding-left: 1.5rem;
  @media (min-width: 768px) {
    padding-left: 0.25rem;
    padding-right: 0.25rem;
  }
`;

const StyledRow = styled(Row)`
  padding-bottom: 3rem;
  justify-content: center;
`;

const StyledCol = styled(Col)`
  text-align: center;
  @media (min-width: 992px) {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }
  h2 {
    font-weight: 600;
  }

  p {
    margin-left: auto;
    margin-right: auto;
    width: 75%;
  }
`;
