import * as React from "react";
import Section from "./Section";
import { BACKGROUND_LIGHT_BLUE_COLOR } from "../../../theme";
import Container from "../../atoms/Container";
import Row from "../../atoms/Row";
import Col from "../../atoms/Col";
import styled from "styled-components";
import logo5 from "../../../assets/img/media/logo5.png";
const Media: React.FC = (_) => {
  return (
    <Section backgroundColor={BACKGROUND_LIGHT_BLUE_COLOR}>
      <Container>
        <Row>
          <Col>
            <Intro>導入企業</Intro>
          </Col>
        </Row>
        <Row>
          <CompanyLogo src={logo5} />
          <CompanyLogo src={logo5} />
          <CompanyLogo src={logo5} />
          <CompanyLogo src={logo5} />
          <CompanyLogo src={logo5} />
          <CompanyLogo src={logo5} />
        </Row>
      </Container>
    </Section>
  );
};

export default Media;

const Intro = styled.h4`
  padding-bottom: 3rem;
  text-align: center;
  font-size: 24px;
  margin-bottom: 0.5rem;
  font-family: inherit;
  font-weight: 500;
  line-height: 1.2;
`;

const CompanyLogoWrapper = styled.div`
  flex: 0 0 50%;
  max-width: 50%;
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  @media (min-width: 768px) {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  img {
    filter: grayscale(100%);
    max-width: 100%;
  }
`;

const CompanyLogo: React.FC<{ src: string }> = ({ src }) => {
  return (
    <CompanyLogoWrapper>
      <img src={src} alt="" />
    </CompanyLogoWrapper>
  );
};
