import React from "react";
import { useHistory } from "react-router-dom";
import AccountTemplate from "../../templates/AccountTemplate";
import PrimaryButton from "../../atoms/PrimaryButton";
import Container from "../../atoms/Container";

const SignupComplete: React.FC = (_) => {
  const { push } = useHistory();
  return (
    <AccountTemplate title="会員登録">
      <Container>
        会員登録が完了しました
        <PrimaryButton onClick={() => push("/articles")}>
          記事一覧ページを見る
        </PrimaryButton>
      </Container>
    </AccountTemplate>
  );
};
export default SignupComplete;
