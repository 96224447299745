import * as React from "react";
import image from "../../../assets/img/hero.png";
import styled from "styled-components";
import Container from "../../atoms/Container";
import Section from "./Section";
import { BACKGROUND_COLOR } from "../../../theme";
import Row from "../../atoms/Row";
import PrimaryButton from "../../atoms/PrimaryButton";
const Hero: React.FC = () => {
  return (
    <Section backgroundColor={BACKGROUND_COLOR}>
      <StyledContainer>
        <StyledRow>
          <CatchCopyWrapper>
            <h1>
              ここにプロダクトの説明を書くよ！！<b>ベネフィットも</b>
            </h1>
            <Lead>詳細の文章をここに書くよそして使い方も</Lead>
            <PrimaryButton>CTAButton</PrimaryButton>
          </CatchCopyWrapper>
          <ImageWrapper>
            <Image src={image} />
          </ImageWrapper>
        </StyledRow>
      </StyledContainer>
    </Section>
  );
};

export default Hero;

const StyledContainer = styled(Container)`
  text-align: center;
  @media (min-width: 992px) {
    text-align: left;
  }
`;

const StyledRow = styled(Row)`
  align-items: center;
`;

const CatchCopyWrapper = styled.div`
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  @media (min-width: 768px) {
    flex: 0 0 100%;
    max-width: 100%;
  }
  @media (min-width: 992px) {
    flex: 0 0 50%;
    max-width: 50%;
  }
  h1 {
    font-size: 2.5rem;
    margin-bottom: 0.5rem;
    font-family: inherit;
    font-weight: 500;
    line-height: 1.2;
  }
`;

const ImageWrapper = styled.div`
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin: auto;
  @media (min-width: 768px) {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }
  @media (min-width: 992px) {
    margin-left: auto;
    padding-top: 0;
    margin-right: 0;
    flex: 0 0 50%;
    max-width: 50%;
  }
`;

const Image = styled.img`
    max-width: 100%;
    height: auto;
    vertical-align: middle;
    border-style: none;
}
`;

const Lead = styled.p`
  margin-bottom: 3rem;
  font-size: 1.25rem;
  font-weight: 300;
`;
