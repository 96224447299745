import * as React from "react";
import Section from "./Section";
import { BACKGROUND_COLOR } from "../../../theme";
import { Container, Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Pricing from "../../molucules/Pricing";
import { useGetApi } from "../../../hooks/useGetApi";
import { GetPlanListResponse } from "../../../types/models";
const useStyles = makeStyles((theme) => ({
  heroContent: {
    padding: theme.spacing(8, 0, 6),
  },
  cardHeader: {
    backgroundColor:
      theme.palette.type === "light"
        ? theme.palette.grey[200]
        : theme.palette.grey[700],
  },
  cardPricing: {
    display: "flex",
    justifyContent: "center",
    alignItems: "baseline",
    marginBottom: theme.spacing(2),
  },
}));

const Price: React.FC = () => {
  const classes = useStyles();
  const { data } = useGetApi<GetPlanListResponse>(`/plans`);
  return (
    <Section backgroundColor={BACKGROUND_COLOR}>
      <Container maxWidth="sm" component="main" className={classes.heroContent}>
        <Typography
          component="h1"
          variant="h2"
          align="center"
          color="textPrimary"
          gutterBottom
        >
          プラン
        </Typography>
        <Typography
          variant="h5"
          align="center"
          color="textSecondary"
          component="p"
        >
          豊富な料金プランです。
        </Typography>
      </Container>
      <Container maxWidth="md" component="main">
        <Grid container spacing={5} alignItems="flex-end">
          {data?.plans.map(
            ({
              name,
              planId,
              price,
              featuredMessage,
              description,
              interval,
            }) => (
              <Pricing
                key={planId}
                title={name}
                planId={planId}
                price={price}
                buttonText="登録する"
                description={description}
                subheader={featuredMessage ?? ""}
                featured={featuredMessage !== ""}
                buttonVariant={
                  featuredMessage !== "" ? "outlined" : "contained"
                }
                interval={interval}
              />
            )
          )}
        </Grid>
      </Container>
    </Section>
  );
};

export default Price;
