import * as React from "react";
import styled from "styled-components";
import { BACKGROUND_COLOR, PRIMARY_COLOR } from "../../theme";
import Logo from "../atoms/Logo";
import NavItem from "../atoms/NavItem";
import { IconButton, useMediaQuery, MenuItem, Menu } from "@material-ui/core";
import device from "../../modules/device";
import MenuIcon from "@material-ui/icons/Menu";
import { PopoverProps } from "@material-ui/core/Popover";
import { useHistory } from "react-router-dom";
import { useContext } from "react";
import { UserContext } from "../../contexts/UserContext";
import { isAuthorized, removeToken } from "../../modules/auth";

const Header: React.FC = () => {
  const isSP = useMediaQuery(device.laptop);
  const history = useHistory();
  const [anchorEl, setAnchorEl] = React.useState<PopoverProps["anchorEl"]>(
    null
  );
  const handleClick = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    setAnchorEl(event.currentTarget);
  };

  const logout = () => {
    handleClose();
    removeToken();
    history.push("/");
  };

  const login = () => {
    history.push("/login");
  };

  const { user } = useContext(UserContext);

  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <NavBar>
      <Container>
        <Logo />
        {isSP ? (
          <>
            <IconButton
              edge="start"
              aria-label="menu"
              onClick={handleClick}
              color="primary"
            >
              <MenuIcon />
            </IconButton>

            <Menu
              id="simple-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <MenuItem onClick={handleClose}> {user.name}</MenuItem>
              <MenuItem onClick={handleClose}>会社概要</MenuItem>
              {isAuthorized() ? (
                <>
                  <MenuItem onClick={logout}>ログアウト</MenuItem>
                </>
              ) : (
                <>
                  <MenuItem onClick={login}>ログイン</MenuItem>
                  {/*<MenuItem onClick={handleClose}>会員登録</MenuItem>*/}
                </>
              )}
            </Menu>
          </>
        ) : (
          <NavBarCollapse>
            <Nav>
              <NavItem to="https://www.spacom.jp/corp/index.html">会社概要</NavItem>
              {isAuthorized() ? (
                <>
                  <NavItem to="/articles">記事一覧</NavItem>
                  {user.name}
                  <Item>
                    <NavLink onClick={logout}>ログアウト</NavLink>
                  </Item>
                </>
              ) : (
                <>
                  <NavItem to="/login">ログイン</NavItem>
                  {/*<PrimaryButton*/}
                  {/*  onClick={() => {*/}
                  {/*    history.push("/signup");*/}
                  {/*  }}*/}
                  {/*>*/}
                  {/*  会員登録*/}
                  {/*</PrimaryButton>*/}
                </>
              )}
            </Nav>
          </NavBarCollapse>
        )}
      </Container>
    </NavBar>
  );
};

export default Header;

const NavBar = styled.nav`
  font-weight: 400;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
  display: flex;
  padding: 0.5rem 1rem;
  align-items: center;
  background-color: ${BACKGROUND_COLOR} !important;
  @media (min-width: 992px) {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
`;

const NavBarCollapse = styled.div`
  flex-grow: 1;
  align-items: center;
  @media (min-width: 992px) {
    display: flex;
    flex-basis: auto;
  }
  font-weight: 400;
`;

const Container = styled.div`
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  @media (min-width: 576px) {
    max-width: 540px;
  }
  @media (min-width: 768px) {
    max-width: 720px;
  }
  @media (min-width: 992px) {
    flex-wrap: nowrap;
    max-width: 960px;
  }
  @media (min-width: 1200px) {
    max-width: 1140px;
  }
`;

const Nav = styled.ul`
  display: flex;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
  margin-top: 0;
  margin-left: auto;
  align-items: center;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
  @media (min-width: 992px) {
    flex-direction: row;
  }
`;

const Item = styled.li`
  display: list-item;
  text-align: -webkit-match-parent;
`;

const NavLink = styled.a`
  @media (min-width: 992px) : {
    padding-right: 0.9rem;
    padding-left: 0.9rem;
  }
  display: block;
  padding: 0.8rem 1rem;
  color: ${PRIMARY_COLOR};
  text-decoration: none;
  background-color: transparent;
`;
