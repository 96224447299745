import * as React from "react";
import styled from "styled-components";
const Section: React.FC<SectionProps> = ({ backgroundColor, children }) => {
  return <Root backgroundColor={backgroundColor}>{children}</Root>;
};

export default Section;

const Root = styled.section<SectionProps>`
  background-color: ${props => props.backgroundColor};
  background-size: cover;
  border-radius: 0;
  padding: 2rem 1rem;
  border-radius: 0.3rem;
  @media (min-width: 576px) {
    padding: 4rem 2rem;
  }
  @media (min-width: 768px) :nth-of-type(1) {
    padding: 9rem 1rem;
  }
  @media (min-width: 768px) {
    padding: 7rem 1rem;
  }
`;

interface SectionProps {
  backgroundColor: string;
}
