import React from "react";
import BasicTemplate from "../../templates/BasicTemplate";
import Hero from "./Hero";
import Price from "./Price";
import Media from "./Media";
import Features from "./Features";

const Top: React.FC = _ => {
  return (
    <BasicTemplate>
      <Hero />
      <Media />
      <Features />
      <Price />
    </BasicTemplate>
  );
};

export default Top;
