import * as React from "react";
import styled from "styled-components";

const Feature: React.FC<FeatureProps> = ({
  title,
  description,
  imageUri,
  imagePosition = "left"
}) => {
  return (
    <Root>
      {imagePosition === "left" ? (
        <>
          <LeftImageContainer>
            <Image src={imageUri} />
          </LeftImageContainer>
          <RightFeatureContainer>
            <h2>
              <strong>{title}</strong>
            </h2>
            <p>{description}</p>
          </RightFeatureContainer>
        </>
      ) : (
        <>
          <LeftFeatureContainer>
            <h2>
              <strong>{title}</strong>
            </h2>
            <p>{description}</p>
          </LeftFeatureContainer>
          <RightImageContainer>
            <Image src={imageUri} />
          </RightImageContainer>
        </>
      )}
    </Root>
  );
};

const Root = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
  text-align: left;
  padding-top: 3rem;
  align-items: center;
  padding-bottom: 3rem;
`;
export default Feature;

const LeftImageContainer = styled.div`
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  @media (min-width: 768px) {
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }
`;

const RightFeatureContainer = styled.div`
  @media (min-width: 768px) {
    margin: auto;
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
    position: relative;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
  }
  h2 {
    font-size: 2rem;
    margin-bottom: 0.5rem;
    font-family: inherit;
    font-weight: 500;
    line-height: 1.2;
  }
  p {
    font-size: 1.25rem;
    font-weight: 300;
    margin-top: 0;
    margin-bottom: 1rem;
  }
`;

const Image = styled.img`
  max-width: 100%;
  height: auto;
  vertical-align: middle;
  border-style: none;
`;

const LeftFeatureContainer = styled.div`
  @media (min-width: 768px) {
    margin: auto;
    order: 5;
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
    position: relative;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
  }
  h2 {
    font-size: 2rem;
    margin-bottom: 0.5rem;
    font-family: inherit;
    font-weight: 500;
    line-height: 1.2;
  }
  p {
    font-size: 1.25rem;
    font-weight: 300;
    margin-top: 0;
    margin-bottom: 1rem;
  }
`;

const RightImageContainer = styled.div`
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  @media (min-width: 768px) {
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
    order: 5;
    margin: auto;
  }
`;

export interface FeatureProps {
  title: string;
  description: string;
  imageUri: string;
  imagePosition?: "left" | "right";
}
